<template>
  <div class="form-container" v-if="show">
    <div class="form-modal">
      <i class="pi pi-times form__pipedrive__close" @click="show = false;"></i>

      <PipedriveContactForm title="Formulaire de contact"></PipedriveContactForm>
    </div>
  </div>
</template>


<script setup lang="ts">
import {useNuxtApp} from '#imports';
import {ref} from '@vue/reactivity';

let show = ref(false);

// ## Event
const {$emitter} = useNuxtApp();

interface ContactModalEvent {
  show: boolean
}

$emitter.on('contactModalEvent', (event: ContactModalEvent) => show.value = event.show)
</script>


<style lang="scss" scoped>
.form__pipedrive__close {
  position: absolute;
  top: 18px;
  right: 18px;

  cursor: pointer;
}
</style>
