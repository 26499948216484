<template>
  <footer v-if="show">
    <div class="mt-6 print-none">
      <div class="flex justify-content-around align-items-center">
        <div>
          <NuxtImg format="webp" src="/img/logo-300_217.webp" alt="Blockproof" width="93px" height="67px"
                   @click="showResetInput = true;"/>
          <!--        <div v-if="showResetInput">-->
          <!--          <input type="email" v-model="formEmail" placeholder="email demo account to reset"-->
          <!--                 v-on:keyup.enter="resetDemoRegister">-->
          <!--        </div>-->
        </div>

        <div>
        </div>

        <div>
          <div>Ressources utiles</div>

          <div v-for="item in resourcesItems" class="text-sm">
            <router-link v-if="item.route"
                         class="text-color-secondary"
                         :to="item.route">
              {{ item.label }}
            </router-link>
            <a v-if="item.url"
               class="text-color-secondary"
               :href="item.url" :target="item.target">
              {{ item.label }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="mt-6 py-4 text-center text-sm print-none">-->
    <!--      &lt;!&ndash;      <span class="mx-5">© 2019 Block I.T</span>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <br class="lg:hidden">&ndash;&gt;-->
    <!--      <NuxtLink to="/privacy" class="mx-5">-->
    <!--        <span class="text-900">Conditions Générales d'Utilisation</span>-->
    <!--      </NuxtLink>-->
    <!--      <br class="lg:hidden">-->
    <!--      <NuxtLink to="/data-protection" class="mx-5">-->
    <!--        <span class="text-900">Politique de protection des données</span>-->
    <!--      </NuxtLink>-->
    <!--    </div>-->

    <div class="my-4 flex justify-content-center text-sm">
      <NuxtLink to="/privacy" class="mx-5">
        <span class="text-900">Conditions Générales d'Utilisation</span>
      </NuxtLink>
      <NuxtLink to="/data-protection" class="mx-5">
        <span class="text-900">Politique de protection des données</span>
      </NuxtLink>
    </div>

  </footer>
</template>


<script setup lang="ts">
import {useRuntimeConfig} from '#app';
import {useNuxtApp} from '#imports';
import {ref} from '@vue/reactivity';
import {MenuItem} from "primevue/menuitem";


const runtimeConfig = useRuntimeConfig();

let show = ref(true);
let showResetInput = ref(false);

let formEmail = '';

const resourcesItems: MenuItem[] = [
  {
    label: 'Blog RGPD',
    route: '/blog'
  },
  {
    label: 'Formations',
    route: '/formations'
  },
  {
    label: 'Podcast',
    url: 'https://podcast.ausha.co/in-my-data',
    target: 'blank'
  }
];


const resetDemoRegister = async () => {
  try {
    const response = await $fetch(runtimeConfig.public.apiBase + '/api/reset', {
      method: 'POST',
      body: {email: formEmail}
    });

    showResetInput.value = false;
    formEmail = '';
  } catch (error) {
    console.error(error); // TODO
    window.alert('Une erreur est survenue, veuillez essayez de nouveau.')
  }
}

// ## Event
const {$emitter} = useNuxtApp();

interface ShowFooterEvent {
  show: boolean
}

$emitter.on('showFooterEvent', (event: ShowFooterEvent) => show.value = event.show);
</script>


<style lang="scss" scoped>
</style>
