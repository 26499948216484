<template>
  <header v-if="show">
    <div class="header-container print-none px-5
                flex align-items-center justify-content-between lg:flex-row-reverse"
         :class="{'header-shadow' : headerShadowClass}">

      <!-- Desktop Version : Connection Button-->
      <div class="hidden lg:block px-4">
        <NuxtLink to="https://app.blockproof.fr" target="_blank" rel="external" class="ga-button-connect">
          <PrimeButton label="Se connecter" icon="pi pi-user" severity="secondary" link/>
        </NuxtLink>
      </div>

      <!-- Desktop Version : Contact Button-->
      <div class="hidden lg:block px-5">
        <PrimeButton label="Contacter"
                     class="text-sm lg:text-lg ga-button-contact"
                     raised size="large"
                     @click="$emitter.emit('contactModalEvent', {show: true})"/>
      </div>

      <!-- Menu -->
      <div class="lg:flex-1 flex justify-content-center">
        <PrimeMenubar :model="menuItems">
          <template #item="{ item, props, hasSubmenu }">
            <router-link v-if="item.route"
                         v-slot="{ href, navigate }" :to="item.route" custom>
              <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <!--              <span :class="item.icon"/>-->
                <span class="font-normal">{{ item.label }}</span>
              </a>
            </router-link>
            <a v-if="item.url"
               v-ripple :href="item.url" :target="item.target" v-bind="props.action">
              <!--            <span :class="item.icon"/>-->
              <span class="font-normal">{{ item.label }}</span>
            </a>
            <span v-if="hasSubmenu"
                  v-ripple v-bind="props.action">
              <span class="font-normal">{{ item.label }}</span>
              <span class="pi pi-fw pi-angle-down ml-2"/>
            </span>
          </template>
        </PrimeMenubar>
      </div>

      <!-- Mobile Version : Contact Button-->
      <div class="lg:hidden">
        <PrimeButton label="Contacter"
                     class="text-sm lg:text-lg ga-button-contact"
                     raised size="large"
                     @click="$emitter.emit('contactModalEvent', {show: true})"/>
      </div>

      <!-- Logo -->
      <NuxtLink to="/">
        <PrimeImage src="/img/logo-300_217.webp" alt="Blockproof" imageClass="h-3rem lg:h-4rem"/>
      </NuxtLink>
    </div>
    <div class="header-height"></div>
  </header>
</template>


<script setup lang="ts">
import {useNuxtApp} from '#imports';
import {ref} from '@vue/reactivity';
import {MenuItem} from 'primevue/menuitem';
import {onBeforeUnmount, onMounted} from 'vue';


let show = ref(true);
let showMenu = ref(false);
let headerShadowClass = ref(false);

const menuItems: MenuItem[] = [
  {
    label: 'Medico-social',
    route: '/medico-social'
  },
  {
    label: 'Entreprises',
    route: '/entreprises'
  },
  {
    label: 'Qui sommes-nous ?',
    // icon: 'pi pi-palette',
    route: '/a-propos'
  },
  {
    label: 'Devis',
    route: '/devis-rgpd'
  },
  {
    label: 'Ressources',
    items: [
      {
        label: 'Blog RGPD',
        route: '/blog'
      },
      {
        label: 'Avis Clients',
        route: '/avis-clients'
      },
      {
        label: 'Formations',
        route: '/formations'
      },
      {
        label: 'Podcast',
        url: 'https://podcast.ausha.co/in-my-data',
        target: 'blank'
      }
    ]
  },
];

// ## Event
const {$emitter} = useNuxtApp();

interface ShowNavbarEvent {
  show: boolean
}

$emitter.on('showNavbarEvent', (event: ShowNavbarEvent) => show.value = event.show);

// ## Hide on scroll
const handleScroll = () => headerShadowClass.value = window.scrollY > 30;

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
})
</script>


<style lang="scss" scoped>
//@import "assets/style/default.scss";


.p-menubar {
  border: none;
  background: none;
}

::v-deep(.p-menubar:not(.p-menubar-mobile)) {
  .p-menubar-root-list > .p-menuitem {
    //min-width: 13rem;
    margin-left: 3rem;
  }
}

::v-deep(.p-menubar.p-menubar-mobile) {
  .p-menubar-root-list {
    min-width: 14rem;
    width: fit-content;
    max-width: 90vw;
  }
}


.header-container {
  position: fixed;
  z-index: 2;
  top: 0;
}

.header-container, .header-height {
  width: 100vw;
  height: 80px;
}


.header-shadow {
  background-color: white;
  box-shadow: 0 0 50px 0 rgba(28, 15, 125, 0.05);
}

/*.header-menu {*/
/*background-color: white;*/
/*display: inline-flex;*/
/*align-items: center;*/
/*margin-left: auto;*/
/*}*/

//.header-link {
//  color: #414660;
//}
//
//.header-menu {
//  font-size: 1rem;
//}
//
//.header-menu li {
//  height: fit-content;
//  margin-left: 32px;
//}
//
//.header-menu-item a {
//  color: inherit;
//}
//
//.header-menu-item a:visited {
//  color: #47494e;
//}
//
//.connect-icon {
//  padding-top: 8px;
//  font-size: 32px;
//  color: #646464;
//}

//@media screen and (max-width: 700px) {
//  .header-container {
//    height: 64px;
//    padding: 0 20px;
//  }
//
//  .header-height {
//    height: 64px;
//  }
//
//  .logo-small {
//    width: 70px;
//  }
//
//  .header-menu {
//    z-index: 1;
//    position: absolute;
//    left: 0;
//    top: 100%;
//
//    width: 100vw;
//    height: 100vh;
//    padding-top: 1.5rem;
//
//    overflow: hidden;
//    background-color: white;
//  }
//
//  .header-link {
//    font-size: 1.5rem;
//    line-height: 3rem;
//  }
//}
</style>
